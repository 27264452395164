import type {RefObject} from 'react';
import {useCallback, useEffect, useState, useRef} from 'react';
import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import {useOnClickOutside} from '@/hooks/useOnClickOutside';
import Modal, {ModalColorScheme} from '@/components/shared/Modal/Modal';
import {ModalSizeEnum} from '@/enums';
import {loadScript} from '@/hooks/useLoadScript';
import {HERO_VIDEO_MODAL_HASH} from '@/pages/shopify.com/($locale)/_index/components/Hero/constants';

interface VideoModalProps {
  children: React.ReactNode;
  className?: string;
  colorScheme?: ModalColorScheme;
  id?: string;
  isActive: boolean | null;
  pictureInPicture?: boolean;
  description?: string;
  returnFocusElement: RefObject<HTMLButtonElement>;
  toggleVideoModal: () => void;
}

const styles = cva(
  'bg-coal-black/90 [&>div>button]:top-[-4rem] [&>div>button]:lg:mr-3xl',
  {
    variants: {
      isPictureInPicture: {
        true: [
          'sm:w-[278px] md:w-[334px] lg:w-[622px] xl:w-[686px]',
          'sm:inset-[auto_0_var(--space-2xl)_auto] sm:bg-transparent sm:pt-9 sm:[&>div>button]:-top-9',
        ],
        false: '',
      },
      isVisible: {
        true: 'flex',
        false: 'hidden',
      },
    },
  },
);

const WISTIA_SCRIPT = '//fast.wistia.com/assets/external/E-v1.js';

export default function VideoModal({
  children,
  className,
  colorScheme,
  id,
  isActive,
  pictureInPicture = false,
  description,
  returnFocusElement,
  toggleVideoModal,
}: VideoModalProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [isPictureInPicture, setIsPictureInPicture] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [animationEnded, setAnimationEnded] = useState(false);
  const [hasLoadedWistia, setHasLoadedWistia] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useOnClickOutside(() => handleOutsideClick());

  const handleOutsideClick = useCallback(() => {
    if (isActive && !isPictureInPicture) {
      toggleVideoModal();
    }
  }, [isActive, isPictureInPicture, toggleVideoModal]);

  const handleClose = () => {
    if (!isActive) return;

    returnFocusElement.current?.focus();
    toggleVideoModal();
    setIsPictureInPicture(false);
    setAnimationEnded(false);

    if (pictureInPicture) {
      window.scrollTo(0, document.documentElement.scrollTop);
    } else {
      window.scrollTo(0, scrollPosition);
    }
  };

  const handleScroll = useCallback(() => {
    if (!isPictureInPicture && window.scrollY > 15) {
      if (!document.startViewTransition) {
        setIsPictureInPicture(true);
        return;
      }

      document.startViewTransition(() => {
        setIsPictureInPicture(true);
      });
    }

    if (isPictureInPicture && window.scrollY === 0) {
      if (!document.startViewTransition) {
        setIsPictureInPicture(false);
        return;
      }

      document.startViewTransition(() => {
        setIsPictureInPicture(false);
      });
    }
  }, [isPictureInPicture]);

  useEffect(() => {
    if (isActive && !hasLoadedWistia) {
      loadScript(WISTIA_SCRIPT);
      setHasLoadedWistia(true);
    }
  }, [isActive, hasLoadedWistia]);

  useEffect(() => {
    if (isActive === null) return;

    if (isActive) {
      setScrollPosition(window.scrollY);
    }

    if (!document.startViewTransition) {
      setIsVisible(isActive);
      return;
    }

    document.startViewTransition(() => {
      setIsVisible(isActive);
    });
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      timeoutRef.current = setTimeout(() => {
        setAnimationEnded(true);
      }, 500);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isActive, setAnimationEnded]);

  // Set focus to modal close button after modal open animation ends
  useEffect(() => {
    if (animationEnded) {
      const closeBtn = document.querySelector(
        '#modal button[data-component-name="close"]',
      ) as HTMLElement;

      closeBtn?.focus();
    }
  }, [animationEnded]);

  useEffect(() => {
    if (isActive === null) return;

    if (isActive) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isActive, handleScroll]);

  useEffect(() => {
    if (isVisible && !window.location.hash) {
      history.pushState(null, '', HERO_VIDEO_MODAL_HASH);
    }

    if (window.location.hash === HERO_VIDEO_MODAL_HASH && !isVisible) {
      history.pushState(null, '', window.location.pathname);
    }
  }, [isVisible]);

  return (
    <Modal
      handleClose={() => handleClose()}
      id={id || 'modal'}
      isActive={!!isActive}
      pictureInPicture={pictureInPicture}
      modalSize={ModalSizeEnum.Full}
      colorScheme={colorScheme || ModalColorScheme.Dark}
      className={twMerge(styles({isVisible, isPictureInPicture}), className)}
      {...(description ? {'aria-label': description} : null)}
    >
      <div className="lg:px-3xl" style={{viewTransitionName: 'wrapper'}}>
        <div data-click-outside="dismiss">{children}</div>
      </div>
    </Modal>
  );
}
